import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./edit.scss";
import { userService } from "../../../services";
import EditForm from "./form";


class AccountEdit extends React.Component {
    state = {
        user: {},
        uploading: false
    };

    async componentDidMount() {
        let { user } = await userService.fetch(this.props.token);
        
        this.setState({
            user: user.data,
        });
    }

    handleImage = async (e) => {
        this.setState({
            uploading: true
        })

        const files = Array.from(e.target.files);   
        let preview = URL.createObjectURL(files[0]);
        let response = await userService.updateImage(files[0], this.props.token);

        let tempUser = this.state.user;
        tempUser.full_path_image = preview;

        this.setState({
            user: tempUser,
            uploading: false
        });
    }

    render() {
        return (
            <section className='account-edit custom-form'>
                <div className='container'>
                    <div className='user-image' style={{backgroundImage: `url(${this.state.user.full_path_image})`}}></div>

                    {/* <input type="file" name="imageUploader" ref={(ref) => this.fileUpload = ref} onChange={this.handleImage} />
                    <button onClick={() => this.fileUpload.click()} className='edit-link' disabled={this.state.uploading}>
                            {this.state.uploading ? "Updating..." : "Update photo"}
                    </button> */}
                    <p className="profile-span">* Please contact us if you wish to change your picture.</p>

                    <div className='edit-form custom-form-form'>
                        <h3 className='lg-subtitle left'>Account</h3>
                        <h2 className='lg-title left'>Info</h2>

                        <EditForm user={this.state.user} />
                    </div>
                </div>
            </section>
        )
    }
}

function mapState(state) {
    const { token } = state.authentication;
    return { token };
}

export default withRouter(connect(mapState)(AccountEdit));
