import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { userActions } from '../../../actions/user.actions';
import { Link, withRouter } from "react-router-dom";
import FormGroup from "../../ui/form-group";
import SelectGroup from "../../ui/select-group";
import uploadImage from "./assets/upload.svg";
import { Form } from 'react-formik-ui';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";

class RegisterForm extends React.Component {
    state = {
        image: false,
        imagePreview: false,
        uploading: false
    }

    async attemptRegister(values) {
        confirmAlert({
            title: 'Please make sure you have selected a clear picture.',
            message: 'Your picture will be used to identify you at events.',
            buttons: [
              {
                label: 'Register',
                onClick: () => {
                    var d = Date.parse(values.month + "1, 2012");
                    let month;

                    if(!isNaN(d)){
                        month = new Date(d).getMonth() + 1;
                    }

                    let req = values;
                    let day = ("0" + values.day).slice(-2);
                    month = ("0" + month).slice(-2);
                    req.date_of_birth = "" + values.year + "-" + month + "-" + day;
                    req.image = this.state.image;

                    this.props.register(req);
                }
              },
              {
                label: 'Go Back',
                onClick: () => {}
              }
            ]
          });
    }

    handleImage = e => {
        const files = Array.from(e.target.files);   
        this.setState({
            imagePreview: URL.createObjectURL(files[0])
        });
        this.setState({
            image: files[0]
        });
    }

    render() {
        const { registering, alert } = this.props;

        const imagePreviewStyle = {
            backgroundImage: this.state.imagePreview ? `url(${this.state.imagePreview})` : null
        };
        
        return (
            <Formik
                validateOnChange={false}

                initialValues={{
                    full_name: '',
                    email: '',
                    password: '',
                    mobile_number: '',
                    month: '',
                    day: '',
                    year: '',
                    gender: '',
                    payFor: '',
                    terms: ''

                    // full_name: 'Andy Abi Haidar',
                    // email: 'andy@yllw.com',
                    // password: 'password',
                    // mobile_number: '70198017',
                    // date_of_birth: '1995-06-01',
                    // gender: 'Male',
                    // payFor: '',
                    // terms: 1
                }}

                validationSchema={Yup.object().shape({
                    full_name: Yup.string()
                        .required("Full name is required.")
                        .min(5, "Full name must be a minimum of 5 characters."),
                    email: Yup.string()
                        .required("Email address is required.")
                        .email("Email address must be valid."),
                    password: Yup.string()
                        .required("Password is required.")
                        .min(6, "Password must be a minimum of 6 characters."),
                    mobile_number: Yup.string()
                        .required("Mobile number is required.")
                        .min(8, "Mobile number must be a minimum of 6 characters."),
                    month: Yup.string()
                        .required("Month of birth is required."),
                    day: Yup.string()
                        .required("Day of birth is required."),
                    year: Yup.string()
                        .required("Year of birth is required.")
                        .length(4, "Year of birth should be valid."),
                    gender: Yup.string()
                        .required("Gender is required.")
                        .matches(/(Male|Female)/),
                    terms: Yup.boolean()
                        .required("Please agree to the terms and conditions.")                    
                })}

                onSubmit={fields => {
                    this.attemptRegister(fields);
                }}

                render={({ values, errors, status, touched }) => (
                    <Form>
                        {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }

                        <FormGroup label="Full Name*" name="full_name" type="text" value={values.full_name}/>
                        <FormGroup label="Email Address*" name="email" type="text" value={values.email} alert={alert} />
                        <FormGroup label="Password*" name="password" type="password" value={values.password}/>
                        <FormGroup label="Mobile Number*" name="mobile_number" type="tel" pattern="[0-9]*" value={values.mobile_number}/>
                        
                        <div className="birthday_group">
                            <SelectGroup label="Date of Birth" name="month" type="text" values={["January", "February", "March", "April", "May", "June", "July", "September", "October", "November", "December"]} value={values.month}/>
                            <FormGroup label="Day" name="day" type="number"  pattern="[0-9]*"value={values.day}/>
                            <FormGroup label="Year" name="year" type="number"  pattern="[0-9]*"value={values.year}/>
                        </div>

                        <SelectGroup label="Gender" name="gender" values={["Male", "Female"]} value={values.gender} />

                        <div className='form-group'>
                            <input type="file" name="picture" ref={(ref) => this.fileUpload = ref} onChange={this.handleImage} />

                            <div className={'file-upload ' + (this.state.imagePreview ? 'preview' : '')} onClick={() => this.fileUpload.click()} style={imagePreviewStyle}>
                                <img src={uploadImage} alt="Upload" />
                                <p>Tap to upload an image of yourself*</p>
                            </div>

                            <p className='notice'>*Your picture will be used to identify you at events. Please make sure you use a clear picture.</p>
                            {alert.fields && alert.fields.hasOwnProperty('image') &&
                                <div className="invalid-feedback">{alert.fields.image}</div>}
                        </div>
                        
                        <div className='form-group'>
                            <div className='checkbox'>
                                <Field type="checkbox" id="terms" name="terms" className='styled-checkbox' />
                                <label htmlFor="terms">I agree to Souk El Akel <Link to="/terms">Terms & Conditions</Link></label>
                                <ErrorMessage name="terms" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        
                        <button type="submit" className="btn full" disabled={registering}>{registering ? "Registering..." : "Register Now"}</button>
                    </Form>
                )}
            />
        )
    }
}

function mapState(state) {
    const { registering } = state.registration;
    const { alert } = state;
    return { registering, alert };
}

const actionCreators = {
    register: userActions.register
}

export default withRouter(connect(mapState, actionCreators)(RegisterForm));