import React from 'react';
import { Link } from 'react-router-dom';
import "./event.scss";
import Packages from "../packages";
import { userService } from "../../services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import api from "./../../utils/api";

class Event extends React.Component {
    state = {
        event: {},
        user: {}
    }

    async componentDidMount() {
        try {
            let { id } = this.props.match.params;
            let event = await api.get(`/events/${id}`);

            this.setState({
                event: event.data.data
            });

            if(this.props.loggedIn) {
                let { user } = await userService.fetch(this.props.token, false);
                console.log(user);
                this.setState({
                    user: user.data
                });
            }
        } catch (e) {
            console.error(`Event call failed. ${e}`);
        }
    }

    render() {
        return (
            <section className='event-page'>
                <div className='container'>
                    <h2 className='lg-title left'>{this.state.event.name}</h2>
                    <p className='lg-p left'>
                        {this.state.event.date} {this.state.event.end_date && this.state.event.end_date != this.state.event.date ? " to " + this.state.event.end_date : ""}<br />
                        <a href={this.state.event.location_link} target="_blank">{this.state.event.location}</a><br />
                        <span>{this.state.event.price}</span>
                    </p>

                    <img src={this.state.event.desktop_banner} alt="Carnaval" className="desktop-image event-image" />
                    <img src={this.state.event.image} alt="Carnaval" className="mobile-image event-image" />

                    <div className="bullet-list" dangerouslySetInnerHTML={{__html: this.state.event.lists}}>
                    </div>

                    {!this.state.user || !this.state.user.has_subscription ? <Packages loggedIn={this.props.loggedIn} /> : null}

                    <br />
                </div>
            </section>

        )
    }
}


function mapState(state) {
    const { token, loggedIn } = state.authentication;
    return { token, loggedIn };
}

export default withRouter(connect(mapState)(Event));