import { userConstants } from '../constants';

export function edit(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { editing: true };
        case userConstants.REGISTER_SUCCESS:
            return {};
        case userConstants.REGISTER_FAILURE:
            return {};
        default:
            return state;
    }
}