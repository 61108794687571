import React from 'react';
import "./home.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Packages from "./../packages";
import moreImg from "./assets/more-sharing.svg";

import registerImg from "./assets/register.svg";
import payImg from "./assets/pay.svg";
import shareImg from "./assets/share.svg";
import { userService } from "../../services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import registerImgWhite from "./assets/register-white.svg";
import payImgWhite from "./assets/pay-white.svg";
import shareImgWhite from "./assets/share-white.svg";

import api from "./../../utils/api";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            heights: ["100%", 0],
            user: {}
        }
    };

    handleScroll = e => {
        let parent = document.getElementById("timeline-container");
        if(parent) {
            let offset = parent.getBoundingClientRect().top - 200;
            let height = parent.clientHeight - 500;

            if(offset <= 0) {
                this.setState({
                    heights: [
                        "100%",
                        Math.min(100, (offset*-1)*100/height) + "%"
                    ]
                })
            } else {
                this.setState({
                    heights: [
                        "100%",
                        0
                    ]
                })
            }
        }
    }

    async componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        try {
            let events = await api.get("/events");
            
            this.setState({
                events: events.data.data,
            });

            if(this.props.loggedIn) {
                let { user } = await userService.fetch(this.props.token, false);
                this.setState({
                    user: user.data
                });
            }
        } catch(e) {
            console.error(`Events call failed. ${e}`);
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const events = this.state.events.map((event, i) => {
            return (<div className='event' key={i}>
                <img src={event.image} alt="Carnaval" />

                <h2 className='lg-title left'>{event.name}</h2>

                <p className='lg-p left'>{event.date}<br />{event.location}</p>

                <a href={'/event/' + event.id} className='btn full'>Learn More</a>
            </div>)
        });

        return (
            <div className='homepage'>
                <section className='homepage-introduction'>
                    <div className='container'>
                        <h1 className='lg-title'>Lebanon's Street Food Market</h1>
                        <p className='lg-p'>Souk El Akel is a Food Celebration showcasing Lebanon’s vibrant culinary world of foods including Lebanese, Middle Eastern and International bites.</p>
                    </div>
                </section>

                {!this.state.user || !this.state.user.has_subscription ? <Packages loggedIn={this.props.loggedIn} /> : null}
                
                {!this.state.user || !this.state.user.has_subscription ? <section className='homepage-more-sharing'>
                    <div className='container'>
                        <img src={moreImg} alt="More Sharing" />

                        <h3 className='lg-subtitle'>Less Paying</h3>
                        <h2 className='lg-title'>More Sharing</h2>

                        <p className='lg-p'>
                            Claim your Foodie Buddies voucher now to enjoy our exclusive buy one get one free deal on all items you order at Souk El Akel!
                        </p>
                    </div>
                </section> : null}

                {!this.state.user || !this.state.user.has_subscription ? <section className='homepage-all-todo' id="timeline-container">
                    <div className='container'>
                        <h3 className='lg-subtitle white'>All you have</h3>
                        <h2 className='lg-title white'>To do is</h2>

                        <div className="timeline-container">
                            {[...Array(2)].map((e, i) => {
                                return (<div id={'timeline-' + i} style={{height: this.state.heights[i]}}  className={'timeline timeline-' + i} key={i}>
                                    <div className='timeline-bar' >
                                        <div className='timeline-bar-container'>
                                            <div className='start-bullet'></div>
                                            <div className='line'></div>
                                            <div className='bullet'></div>
                                            <div className='line'></div>
                                            <div className='bullet'></div>
                                        </div>
                                    </div>

                                    <div className='timeline-items'>
                                        <div className='timeline-item'>
                                            <img src={registerImg} alt="Register" className="regular-image" />
                                            <img src={registerImgWhite} alt="Register" className="white-image" />

                                            <h2 className='lg-title white'>Register</h2>
                                            <p className='lg-p white'>Enter your personal details to become part of the Souk El Akel community.</p>
                                        </div>

                                        <div className='timeline-item'>
                                            <img src={payImg} alt="Pay" className="regular-image" />
                                            <img src={payImgWhite} alt="Pay" className="white-image"/>

                                            <h2 className='lg-title white'>Pay</h2>
                                            <p className='lg-p white'>Pay a yearly fee of just LBP 50,000 to activate your 'buy one get one free' offer on all items you order at Souk El Akel!</p>
                                        </div>

                                        <div className='timeline-item'>
                                            <img src={shareImg} alt="Share" className="regular-image" />
                                            <img src={shareImgWhite} alt="Share" className="white-image" />

                                            <h2 className='lg-title white'>Share</h2>
                                            <p className='lg-p white'>Show your QR code to any of our vendors to benefit from this exclusive offer on the go!</p>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>

                        <div className='button'>
                            {this.props.loggedIn ? 
                                <a href='/account' className='btn white-btn'>Pay Now</a> :
                                <a href='/register' className='btn white-btn'>Register Now</a>}
                        </div>
                    </div>
                </section> : null}

                <section className={"homepage-upcoming-events " + (this.state.user.has_subscription ? "no-pad" : null)}>
                    <div className='container'>
                        <h3 className='lg-subtitle left'>Upcoming</h3>
                        <h2 className='lg-title left'>Events</h2>
                    </div>
                    {this.state.events.length ?
                        <div className='allEvents'>
                            <Carousel 
                                className="events-slideshow"
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                centerMode={true}
                                centerSlidePercentage={100}
                            >
                                {events}
                            </Carousel>
                            
                            <div className='desktop-events'>
                                <div className="container">
                                    {events}
                                </div>
                            </div>
                        </div>
                    : null}
                </section>
            </div>
        );
    }
}

function mapState(state) {
    const { token, loggedIn } = state.authentication;
    return { token, loggedIn };
}

export default withRouter(connect(mapState)(Home));
