import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./account.scss";
import { userService } from "../../services";
import ActiveAccount from "./active";
import InactiveAccount from "./inactive";

class Account extends React.Component {
    state = {
        user: {},
        qr: ""
    };

    async componentDidMount() {
        let { user, qr } = await userService.fetch(this.props.token);

        this.setState({
            user: user.data,
            qr: qr.data.qr_code
        });
    }

    render() {
        let account = this.state.user.has_subscription ?
            <ActiveAccount qr={this.state.qr} user={this.state.user} /> :
            <InactiveAccount qr={this.state.qr} user={this.state.user} />;

        return (
            <div>
                {account}                
            </div>
        )
    }
}

function mapState(state) {
    const { token } = state.authentication;
    return { token };
}

export default withRouter(connect(mapState)(Account));
