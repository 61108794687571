import React from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { userActions } from '../../../actions/user.actions';
import { Link, withRouter } from "react-router-dom";
import FormGroup from "../../ui/form-group";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focusedFields: {},
            filledFields: {}
        }

        this.focusField = this.focusField.bind(this);
        this.blurField = this.blurField.bind(this);
        this.attemptLogin = this.attemptLogin.bind(this);
    }

    focusField(fieldName) {
        let tempFocusedFields = this.state.focusedFields;
        tempFocusedFields[fieldName] = true;

        this.setState({
            focusedFields: tempFocusedFields
        });
    }

    blurField(fieldName, value = "") {
        if(value === "") {
            let tempFocusedFields = this.state.focusedFields;
            delete tempFocusedFields[fieldName];

            this.setState({
                focusedFields: tempFocusedFields
            })
        }
    }

    async attemptLogin({ email, password }) {
        await this.props.login(email, password);
    }

    render() {
        const { loggingIn, alert } = this.props;

        return (
            <Formik
                validateOnChange={false}

                initialValues={{
                    email: "",
                    password: ""
                }}

                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .required("Email address is required.")
                        .email("Email is not valid"),
                    password: Yup.string()
                        .required("Passord is required")
                })}

                onSubmit={fields => {
                    this.attemptLogin(fields);
                }}

                render={({ values, errors, status, touched }) => (
                    <Form>
                        {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }
                        <FormGroup label="Email Address" name="email" type="text" value={values.email} />
                        <FormGroup label="Password" name="password" type="password" value={values.password}>
                            <p className='note'>Forgot password? <a href='/'>Reset</a></p>
                        </FormGroup>
                        
                        <button type="submit" className="btn full" disabled={loggingIn}>Sign In</button>
                        <p className='sign-up'>Don't have an account? <Link to='/register'>Sign Up</Link></p>
                    </Form>
                )}
            />
        )
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return { loggingIn, alert }
};

const actionCreators = {
    login: userActions.login
}

export default withRouter(connect(mapState, actionCreators)(LoginForm));