import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userService } from "../../../services";

class InactiveAccount extends React.Component {
    constructor(props) {
        super(props);

        this.subscribe = this.subscribe.bind(this);
    }

    subscribe = async (event) => {
        event.preventDefault();
        await userService.subscribe(this.props.token);        
        window.location.reload();
    }

    render() {
        let props = this.props;
        return (
            <section className='profile'>
                <div className='profile-image' style={{backgroundImage: `url(${props.user.full_path_image})`}}>
                </div>
                
                <div className='container'>
                    <div className='qr block'>
                        <div className='qr-text'>
                            <h2>Activate your account</h2>
                            <p>Pay the yearly fee online or at any Souk El Akel event to activate your account.</p>
                        </div>
                    </div>

                    <p className='lg-p notice'>Still getting this alert after paying? <a href='/contact'>Contact us</a></p>

                    <h2 className='lg-title'>{props.user.full_name}</h2>
                    <p className='lg-p email'>{props.user.email}</p>

                    <a href={process.env.REACT_APP_PAYMENT_URL + "?user=" + props.user.id + "&returnUrl=" + encodeURI(process.env.REACT_APP_PAYMENT_RETURN_URL)} className='btn full' >Pay Online</a>
                    <p className='lg-p'>Or pay at Souk El Akel</p>
                </div>
            </section>
        )
    }
}

function mapState(state) {
    const { token } = state.authentication;
    return { token };
}

export default withRouter(connect(mapState)(InactiveAccount));
