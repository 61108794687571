import React from 'react';
import facebookImg from "./assets/facebook.svg";
import instaImg from "./assets/instagram.svg";
import "./footer.scss";

const Footer = props => {
    return (
        <div className="footer">
            <div className='container'>
                <p>&copy; 2019 Souk El Akel 
                    <span></span>
                    <a href='/terms'>Terms and Conditions</a>
                </p>

                <ul>
                    <li>
                        <a href='http://facebook.com/soukelakel' target="_blank">
                            <img src={facebookImg} alt="Facebook" />
                        </a>
                    </li>

                    <li>
                        <a href="http://instagram.com/soukelakel" target="_blank">
                            <img src={instaImg} alt="Instagram" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;