import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { Datepicker } from 'react-formik-ui';

class FormGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        }

        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
    }

    focus() {
        this.setState({
            focused: true
        });
    }

    blur(value = '') {
        if(value === '') {
            this.setState({
                focused: false
            })
        }
    }

    render() {
        return (
            <div className={"form-group " + (this.state.focused || this.props.value != '' ? "focused" : "")}>
                <label className="form-label" htmlFor={this.props.name}>{this.props.label}</label>
                {(this.props.type === "date") ?
                    <Datepicker name={this.props.name} id={this.props.name}  className="form-input" onFocus={() => this.focus()} onBlur={() => this.blur(this.props.value)}  dateFormat="Y-MM-dd" />:
                    
                    <Field id={this.props.name} name={this.props.name} className="form-input" type={this.props.type} onFocus={() => this.focus()} onBlur={() => this.blur(this.props.value)} />}
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
                {this.props.alert && 
                    this.props.alert.fields && 
                    this.props.alert.fields.hasOwnProperty(this.props.name) &&
                        <div className="invalid-feedback">{this.props.alert.fields[this.props.name]}</div>
                }
                {this.props.children}
            </div>
        )
    }
}

FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default FormGroup;