import React from 'react';
import "./terms.scss";
import api from "./../../utils/api";

class Terms extends React.Component {
    state = {
        text: ''
    };

    async componentDidMount() {
        let terms = await api.get("/terms");
        
        this.setState({
            text: terms.data.data[0].text
        });
    }
    
    render() {
        return (
            <section className='terms'>
                <div className='container'>
                    <h3 className='lg-subtitle left'>Terms</h3>
                    <h2 className='lg-title left'>And Conditions</h2>

                    <p className="lg-p left">
                        {this.state.text}
                    </p>
                </div>
            </section>
        )
    }
}

export default Terms;