import React from 'react';
import sharingImg from "./assets/sharing-food.svg";
import "./packages.scss";

const Packages = props => {
    return (
        <section className='packages'>
            <div className='container'>
                <h3 className='lg-subtitle'>Love is</h3>
                <h2 className='lg-title'>Sharing Food</h2>

                <div className='package'>
                    <div className='package-image'>
                        <img src={sharingImg} alt="Sharing Food" />
                    </div>

                    <div className='package-title'>
                        <h2>Foodie Buddies</h2>

                        <h3>
                            <span className='superscript'>LBP</span>
                            <span className='price'>50,000</span>
                            <span className='year'>/ Year</span>
                        </h3>
                    </div>
                    
                    <div className='package-info'>
                        <ul>
                            <li>Buy one get one free on all items you order at Souk El Akel</li>
                            <li>Pay once a year, use it every day</li>
                            <li>Becomae part of Souk El Akel Community</li>
                        </ul>
                    </div>

                    <div className='package-button'>
                        {props.loggedIn ? 
                            <a href='/account' className='btn'>
                                Pay Now
                            </a> : 
                            <a href='/register' className='btn'>
                                Register Now
                            </a>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Packages;