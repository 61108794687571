const baseAxios = require('axios');

export const handleError = (error) => {
    let data = error.response.data;
    return Promise.reject(data);
};

export const handleSuccess = (response) => {
    let data = response.data;
    return Promise.resolve(data);
}

export const axios = baseAxios.create({
    baseURL: process.env.REACT_APP_API_URL
});