import React from 'react';
import "./login.scss";
import { connect } from 'react-redux';
import LoginForm from "./form";
import { withRouter } from "react-router-dom";

class Login extends React.Component {
    render() {
        return (
            <section className='custom-form login-form'>
                <div className='container'>
                    <div className='custom-intro'>
                        <h2 className='lg-title'>Welcome back foodie</h2>
                    </div>

                    <div className='custom-form-form'>
                        <a href='/' className='btn blue-btn full'>
                            Continue with Facebook
                        </a>

                        <p className='lg-p'>Or sign in using your email</p>

                        <LoginForm />
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect()(Login));