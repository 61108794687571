import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import "./app.scss";
import MetaTags from 'react-meta-tags';

import Home from "./../home";
import Login from "./../login";
import Header from "./../layout/header";
import Footer from "./../layout/footer";
import Register from "./../register";
import Account from "./../account";
import Terms from "./../terms";
import AccountEdit from "./../account/edit";
import Event from "./../event";
import { history } from "../../helpers";
import api from "./../../utils/api";


class App extends React.Component {
    state = {
        title: '',
        keywords: ''
    }

    async componentDidMount() {
        let meta = await api.get("/meta");

        this.setState({
            title: meta.data.data[0].description,
            keywords: meta.data.data[0].keywords
        });
    }

    render () {
        return (
            <div className='app-content'>
                <MetaTags>
                    <meta name="description" content={this.state.title} />
                    <meta name="keywords" content={this.state.keywords} />
                </MetaTags>

                <Header />

                <Switch history={history}>
                    <Route path="/home" component={Home} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/event/:id" component={Event} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/account/edit" component={AccountEdit} />
                    <Redirect to="/home" />
                </Switch>

                <Footer />
            </div>
        )
    }
}

export default App;