import React from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { userActions } from '../../../../actions/user.actions';
import { withRouter } from "react-router-dom";
import FormGroup from "../../../ui/form-group";

class EditForm extends React.Component {
    attemptEdit = async (fields) => {
        this.props.edit(fields, this.props.token);
    }

    render() {
        const { editing, alert, user } = this.props;

        return (
            <Formik
                validateOnChange={false}
                enableReinitialize={true}

                initialValues={{
                    full_name: user.full_name || '',
                    email: user.email || '',
                    password: ''
                }}

                validationSchema={Yup.object().shape({
                    full_name: Yup.string()
                        .required("Full name is required.")
                        .min(5, "Full name must be a minimum of 5 characters."),
                    email: Yup.string()
                        .required("Email address is required.")
                        .email("Email address must be valid."),
                    password: Yup.string()
                        .min(6, "Password must be a minimum of 6 characters.")
                })}

                onSubmit={fields => {
                    this.attemptEdit(fields);
                }}

                render={({ values, errors, status, touched }) => (
                    <Form>
                        {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>}

                        <FormGroup label="Full Name*" name="full_name" type="text" value={values.full_name} />
                        <FormGroup label="Email Address*" name="email" type="text" value={values.email} />
                        <FormGroup label="New Password" name="password" type="text" value={values.password} />

                        <button type="submit" className="btn full" disabled={editing}>Save Changes</button>
                    </Form>
                )}
             />
        )
    }
}

function mapState(state) {
    const { editing } = state.edit;
    const { alert } = state;
    const { token } = state.authentication;

    return { editing, alert, token }
}

const actionCreators = {
    edit: userActions.edit
}

export default withRouter(connect(mapState, actionCreators)(EditForm));