import React from 'react';

class ActiveAccount extends React.Component {

    render() { 
        let props = this.props;

        return (
            <section className='profile'>
                <div className='profile-image' style={{backgroundImage: `url(${props.user.full_path_image})`}}>
                </div>
                
                <div className='container'>
                    <div className='qr'>
                        <div dangerouslySetInnerHTML={{__html: props.qr}}></div>
                    </div>

                    <p className='lg-p notice'>Show this QR code at any SEA food stand</p>

                    <h2 className='lg-title'>{props.user.full_name}</h2>
                    <p className='lg-p email'>{props.user.email}</p>

                    <div className='profile-info'>
                        <div className='profile-row'>
                            <p>Offer activated on:</p>
                            <p className='red'>{props.user.subscriptions[0].start_date}</p>
                        </div>
                        <div className='profile-row'>
                            <p>Offer valid till:</p>
                            <p className='red'>{props.user.subscriptions[0].end_date}</p>
                        </div>
                        <div className='profile-row'>
                            <p>Payment method:</p>
                            <p className='red'>Online Payment</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ActiveAccount;