import { axios, handleError, handleSuccess } from '../helpers';

export const userService = {
    login,
    register,
    fetch,
    logout,
    subscribe,
    edit,
    updateImage
}

function edit(user, token) {
    return axios.patch("/user", user, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .catch(handleError)
    .then(handleSuccess)
}

function subscribe(token) {
    return axios.post("/subscriptions", {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .catch(handleError)
    .then(handleSuccess);
}

function login(email, password) {
    let request = {
        email,
        password
    };

    return axios.post("/login", request)
        .catch(handleError)
        .then(handleSuccess)
        .then(user => {
            localStorage.setItem('token', user.data.token);
            return user;
        });
}

function logout() {
    localStorage.clear();
}

function register(user) {
    var formData = new FormData();

    for(var key in user) {
        formData.append(key, user[key]);
    }

    return axios.post("/register", formData, {
        headers: {
            'Content-Type': "multipart/form-data"
        }
    })
    .catch(handleError)
    .then(handleSuccess);
}


function updateImage(image, token) {
    var formData = new FormData();

    formData.append("image", image);

    return axios.post("/user/image", formData, {
        headers: {
            'Content-Type': "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    .catch(handleError)
    .then(handleSuccess);
}


function getUserInformation(token) {
    return axios.get("/user", {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(handleSuccess)
    .catch(handleError);
}

function getUserQR(token) {
    return axios.get("/user/qr", {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(handleSuccess)
    .catch(handleError);
}

function fetch(token) {
    return Promise.all([
        getUserInformation(token),
        getUserQR(token)
    ])
    .then(results => {
        return {
            user: results[0],
            qr: results[1]
        }
    })
    .catch(err => {
        console.error(err);
    });
}