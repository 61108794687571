import { userConstants } from "../constants";
import { userService } from "../services";
import { history } from "../helpers";
import { alertActions } from "./";

export const userActions = {
    login,
    register,
    logout,
    edit
};

function edit(user, token) {
    return dispatch => {
        userService.edit(user, token)
            .then(
                user => {
                    dispatch(success(user.data));
                    dispatch(alertActions.success("Successfully edited."));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error.message.toString()));
                    dispatch(alertActions.error(error.message.toString()));
                }
            )
    }

    // function request(user) { return { type: userConstants.EDIT_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_FAILURE, error } }
}

function logout() {
    return dispatch => {
        userService.logout();
        dispatch({ type: userConstants.LOGOUT_REQUEST });
        history.push("/");
    }
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
 
        userService.login(email, password)
            .then(
                user => { 
                    dispatch(success(user.data));
                    dispatch(alertActions.clear());
                    history.push("/");
                },
                error => {
                    dispatch(failure(error.message.toString()));
                    dispatch(alertActions.error(error.message.toString()));
                }
            );
    };
 
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success(user.data));
                    dispatch(alertActions.success("Successfully registered."));
                    history.push("/login");
                },

                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.message.toString(), error.data));
                }
            );
    }

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}