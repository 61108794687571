import React from 'react';
import menuImg from './assets/menu.svg';
import { ReactComponent as Logo } from "./assets/logo.svg";
import closeImg from './assets/close.svg';
import "./header.scss";
import { connect } from 'react-redux';
import { Route, Switch, Link } from "react-router-dom";
import Footer from "./../footer";
import { userActions } from "../../../actions";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            prevScrollpos: window.pageYOffset,
            visible: true
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
      
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    toggleMenu() {
        this.setState({
            toggle: !this.state.toggle
        });
    }

    handleScroll = () => {
        const { prevScrollpos } = this.state;
      
        const currentScrollPos = window.pageYOffset;
        let visible = prevScrollpos > currentScrollPos;
        
        if(!visible && currentScrollPos < 150) visible = true;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    handleLogout = (event, openMenu=true) => {
        event.preventDefault();
        if(openMenu) this.toggleMenu();
        this.props.logout();
    }
    
    render() {
        const { loggedIn } = this.props;
        
        const signInButton = !!loggedIn || <Link to="/login">Sign In</Link>;

        return (
            <header className={this.state.toggle ? "toggled": null}>
                <div className={'header-content ' + (this.state.visible ? "" : "hidden")}>
                    <div className='container'>
                        <div className='menu'>
                            {!!!loggedIn || <Link to="/logout" className="logout-link"  onClick={(e) => this.handleLogout(e, false)}>Logout</Link>}
                            <img src={menuImg} alt="Menu" onClick={this.toggleMenu} />
                        </div>

                        <div className='logo'>
                            <a href='/'>
                                <Logo />
                            </a>
                        </div>

                        <div className='sign-in'>
                            {signInButton}
                            {!!!loggedIn || 
                                <Switch>
                                    <Route
                                        exact
                                        path="/account"
                                        render={() => <Link to="/account/edit">Edit Profile</Link>}
                                    />
                                    <Route 
                                        render={() => <Link to="/account">My Account</Link>}
                                    />
                                </Switch>
                            }
                        </div>
                    </div>


                    <nav >
                        <div className='nav-content'>
                            <img src={closeImg} alt= "Close" className="close-btn" onClick={this.toggleMenu}/>

                            <ul>
                                <li>
                                    <Link to='/' onClick={this.toggleMenu}>Home</Link>
                                </li>

                                {loggedIn ? 
                                    <li>
                                        <Link to="/account" onClick={this.toggleMenu}>My Account</Link>
                                    </li> :
                                    <li>
                                        <Link to="/login" onClick={this.toggleMenu}>Login</Link>
                                    </li>}

                                {!!loggedIn && <li>
                                    <Link to="/logout" onClick={this.handleLogout}>Logout</Link>
                                </li>}

                                <li>
                                    <Link to="/terms" onClick={this.toggleMenu}>Terms</Link>
                                </li>
                            </ul>

                            <Footer />
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

function mapState(state) {
    const { loggedIn } = state.authentication;
    return { loggedIn };
}


const actionCreators = {
    logout: userActions.logout
}

export default connect(mapState, actionCreators)(Header);