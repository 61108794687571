import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import downImage from "./assets/down.svg";

class SelectGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        }

        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
    }

    focus() {
        this.setState({
            focused: true
        });
    }

    blur(value = '') {
        if(value === '') {
            this.setState({
                focused: false
            })
        }
    }

    render() {
        return (
            <div className={"form-group " + (this.state.focused ? "focused" : "")}>
                <label className="form-label" htmlFor={this.props.name}>{this.props.label} <img src={downImage} className="Down" alt="Open" /></label>
                <Field component="select" id={this.props.name} name={this.props.name} className="form-input" onFocus={() => this.focus()} onBlur={() => this.blur(this.props.value)}>
                    <option hidden disabled value=""></option>
                    {this.props.values.map((val, index) => {
                        return <option value={val} key={index}>{val}</option>
                    })}
                </Field>
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
            </div>
        )
    }
}

SelectGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired
}

export default SelectGroup;