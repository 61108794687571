export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    EDIT_REQUEST: 'USERS_EDIT_REQUEST',
    EDIT_SUCCESS: 'USERS_EDIT_SUCCESS',
    EDIT_FAILURE: 'USERS_EDIT_FAILURE',

    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
};