import { userConstants } from '../constants';

let token = localStorage.getItem('token');
const initialState = token ? { loggedIn: true, token } : {};

export function authentication(state = initialState, action) {
  	switch (action.type) {
    	case userConstants.LOGIN_REQUEST:
      		return {
		        loggingIn: true
      		};
    	case userConstants.LOGIN_SUCCESS:
      		return {
				loggedIn: true,
				token: action.user.token
      		};
    	case userConstants.LOGIN_FAILURE:
      		return {};
    	case userConstants.LOGOUT_REQUEST:
      		return {};
    	default:
      		return state
  	}
}