import React from 'react';
import RegisterForm from "./form";
import { Link } from 'react-router-dom';

class Register extends React.Component {
    render() {
        return (
            <section className='custom-form'>
                <div className='container'>
                    <div className='custom-intro'>
                        <h3 className='lg-subtitle left'>Love is</h3>
                        <h2 className='lg-title left'>Sharing Food at Souk El Akel</h2>

                        <p className='lg-p left'>Don't dwell on your cravings, get it now!</p>
                    </div>

                    <div className='custom-form-form'>
                        <Link to='#' className='btn blue-btn full'>
                            Continue with Facebook
                        </Link>

                        <p className='lg-p'>Or register using your email</p>

                        <RegisterForm />
                    </div>
                </div>
            </section>
        )
    }
}

export default Register;